import * as React from "react"
import * as profileComponentStyles from "./profile-component.module.scss"

const ProfileComponent = (props) => {
  return (
    <div className={profileComponentStyles.profileDiv}>
      { props.children }
      <p className={profileComponentStyles.profileName}>{ props.name }</p>
    </div>
  )
}

export default ProfileComponent
