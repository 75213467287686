import * as React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ProfileComponent from "../components/team/profileComponent"
import ArrowRight from "../assets/icons/arrow_right.svg"
import * as teamPageStyles from "./team-page.module.scss"
import * as welcomeSectionStyles from "../components/home/welcome-section.module.scss"
import * as profileComponentStyles from "../components/team/profile-component.module.scss";

const SecondPage = () => (
  <Layout>
    <Seo title="Our Team" />
    <section className='container text-center' style={{marginTop: '8rem'}}>
      <div className="row mb-3">
        <div className="col-lg-12 d-flex flex-column align-items-center">
          <header>
            <h2 className='mb-3'>Our Team</h2>
          </header>
          <p className='mb-3'>
            Our highly qualified and skilled team.
          </p>
          <div className={teamPageStyles.horizontalLine}/>
        </div>
      </div>
      <div className="row" style={{ marginBottom: '7rem' }}>
        <div className="col-md-12 d-flex flex-row flex-wrap justify-content-center">
          <ProfileComponent name={"Zoltán Zimborás"}>
            <StaticImage src="../assets/images/team/zoltan_zimboras.png" alt="Profile picture" className={profileComponentStyles.profileImg}/>
          </ProfileComponent>
          <ProfileComponent name={"Zoltán Kolarovszki"}>
            <StaticImage src="../assets/images/team/zoltan_kolarovszki.png" alt="Profile picture" className={profileComponentStyles.profileImg}/>
          </ProfileComponent>
          <ProfileComponent name={"Rakyta Péter"}>
            <StaticImage src="../assets/images/team/peter_rakyta.png" alt="Profile picture" className={profileComponentStyles.profileImg}/>
          </ProfileComponent>
          <ProfileComponent name={"Tamás Kozsik"}>
            <StaticImage src="../assets/images/team/tamas_kozsik.png" alt="Profile picture" className={profileComponentStyles.profileImg}/>
          </ProfileComponent>
          <ProfileComponent name={"Szabolcs Jóczik"}>
            <StaticImage src="../assets/images/team/szabolcs_joczik.png" alt="Profile picture" className={profileComponentStyles.profileImg}/>
          </ProfileComponent>
          <ProfileComponent name={"Ágoston Kaposi"}>
            <StaticImage src="../assets/images/team/agoston_kaposi.png" alt="Profile picture" className={profileComponentStyles.profileImg}/>
          </ProfileComponent>
          <ProfileComponent name={"Gábor Németh"}>
            <StaticImage src="../assets/images/team/gabor_nemeth.png" alt="Profile picture" className={profileComponentStyles.profileImg}/>
          </ProfileComponent>
          <ProfileComponent name={"Zsófia Kallus"}>
            <StaticImage src="../assets/images/team/zsofia_kallus.png" alt="Profile picture" className={profileComponentStyles.profileImg}/>
          </ProfileComponent>
          <ProfileComponent name={"Tomasz Rybotycki"}>
            <StaticImage src="../assets/images/team/tomasz_rybotycki.png" alt="Profile picture" className={profileComponentStyles.profileImg}/>
          </ProfileComponent>
          <ProfileComponent name={"Michal Oszmaniec"}>
            <StaticImage src="../assets/images/team/michal_oszmaniec.png" alt="Profile picture" className={profileComponentStyles.profileImg}/>
          </ProfileComponent>
          <ProfileComponent name={"Boldizsár Poór"}>
            <StaticImage src="../assets/images/team/boldizsar_poor.png" alt="Profile picture" className={profileComponentStyles.profileImg}/>
          </ProfileComponent>
          <ProfileComponent name={"Kareem El Safty"}>
            <StaticImage src="../assets/images/team/kareem_el_safty.png" alt="Profile picture" className={profileComponentStyles.profileImg}/>
          </ProfileComponent>
        </div>
      </div>
      <div className="row" style={{ marginBottom: '7rem' }}>
        <div className="col-lg-5 order-last order-lg-first">
          <StaticImage className="mr-2"
                       src="../assets/icons/community_abstract.png"
                       alt="Community abstract"
                       quality={100}
                       width={300} />
        </div>
        <div className={`col-lg-7 order-first order-lg-last justify-content-center ${teamPageStyles.piquassoCommunity}`}>
          <h2 className='mb-3'>Join the Piquasso Community</h2>
          <p className='mb-4'>
            Piquasso is an open source project with over 100 active contributors. If you'd like to contribute too, you can do that on our github page.
          </p>
          <button className={`mr-5 ${welcomeSectionStyles.actionButtonSimulator} ${welcomeSectionStyles.actionButtons} ${teamPageStyles.actionBtn}`}
                  role="link"
                  onClick={() => {
                    navigate("https://github.com/Budapest-Quantum-Computing-Group")
                  }}>
            <div className="d-flex justify-content-between align-items-center">
              Visit our Github page
              <div className="d-flex">
                <ArrowRight className="mr-1" style={{opacity: .9, height: "12px", width: "10px"}}/>
                <ArrowRight className="mr-1" style={{opacity: .6, height: "12px", width: "10px"}}/>
                <ArrowRight className="mr-1" style={{opacity: .3, height: "12px", width: "10px"}}/>
              </div>
            </div>
          </button>
        </div>
      </div>
    </section>
  </Layout>
)

export default SecondPage
